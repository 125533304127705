.features {
    box-sizing: border-box;
    max-width: 1024px;
    padding-top: 12%;
    padding-bottom: 10%;
    padding-left: 15%;
    margin-left: auto;
    margin-right: auto;

    // justify-content: space-between;

    // &--center {
    //     padding-left: 0;
    //     text-align: center;
    // }

    &__item {
        padding-top: 40px;
        padding-right: 20px;
        padding-bottom: 40px;
        padding-left: 0px;
        min-height: 150px;

        // &--1a {
        //     margin-left: 20px;
        //     max-width: 500px;
        //     background: url('../img/moment3.svg') no-repeat;
        //     background-size: 50px auto;
        //     flex-grow: 1;
        // }

        &--1 {
            background: url('../img/moment.svg') no-repeat;
            background-size: 90px auto;
            flex-grow: 1;
        }
        &--2 {
            background: url('../img/salary.svg') no-repeat;
            background-size: 50px auto;
            max-width: 300px;
        }
        &--3 {
            background: url('../img/comission.svg') no-repeat;
            background-size: 50px auto;
        }
        &--4 {
            background: url('../img/getout.svg') no-repeat;
            background-size: 50px auto;
            max-width: 300px;
        }
    }
    &__title {
        margin-top: 20px;
    }
}


@media screen and (max-width: 500px) {

    .features {
        padding-left: 80px;
    }

}