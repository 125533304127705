@mixin title {

    font-size: 2rem;

    @media screen and (max-width: 500px) {
        font-size: 1.5rem;
    }
}

@mixin colored-link {

    color: $theme_yellow !important;

    &:hover {
        color: $theme_yellow !important;
    }

}