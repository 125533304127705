// инструкция
.connect-wrap {
    max-width: 800px;
    padding: 0 20px;
    margin: 0 auto;
}

.instruction {
    section {
        margin: 40px auto 60px auto;
    }
    img {
        max-width: 100%;
        margin: 20px auto;
    }
    &__title {
        padding: 0 0 20px 32px;
    }
}

.content {
    padding: 30px 0;
    &__title {
        margin-bottom: 25px; // @include title();
    }
    &__link {

        color: #fff;
        &:hover {
            color: $theme_yellow;
        }
    }
}

.important {
    &__title {
        color: $theme_yellow;
    }
}

.left-bordered {
    border-left: 2px solid $theme_yellow;
    padding: 10px 0 10px 30px;
}



.install {
    &__link {
        @include colored-link();

    }
}

.connect {
    &__link {
        @include colored-link();

    }
}