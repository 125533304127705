// @font-face {
//     font-family: "Oswald";
//     font-style: normal;
//     font-weight: 400;
//     src: url("/fonts/Oswald-Regular.ttf") format(ttf);
//   }

  @font-face {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 400;
    src: url("/fonts/Ubuntu/Ubuntu-Regular.ttf") format(ttf);
  }
  
//   @font-face {
//     font-family: "Open Sans Light";
//     font-style: normal;
//     font-weight: 300;
//     src: url("../fonts/opensanslight.woff2") format(woff2),
//         url("../fonts/opensanslight.woff") format(woff);
//   }
//   @font-face {
//     font-family: "Open Sans Bold";
//     font-style: normal;
//     font-weight: 700;
//     src: url("../fonts/opensansbold.woff2") format(woff2),
//         url("../fonts/opensansbold.woff") format(woff);
//   }