
input.error {
    border: 1px solid #ff0000;
}

input.valid {
    border: 1px solid green;
}

label.error {
    color: #ff0000;
    font-weight: normal;
    font-size: 0.8rem;
}
