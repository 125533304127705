body {
    // background: linear-gradient(to bottom right, #32434b, #000);
    background: linear-gradient(27deg, #151515 5px, transparent 5px) 0 5px,
        linear-gradient(207deg, #151515 5px, transparent 5px) 10px 0px,
        linear-gradient(27deg, #222 5px, transparent 5px) 0px 10px,
        linear-gradient(207deg, #222 5px, transparent 5px) 10px 5px,
        linear-gradient(90deg, #1b1b1b 10px, transparent 10px),
        linear-gradient(#1d1d1d 25%, #1a1a1a 25%, #1a1a1a 50%, transparent 50%, transparent 75%, #242424 75%, #242424);
    background-color: #131313;
    background-size: 20px 20px;

    color: white;
    // font-size: 18px;
    min-height: 100vh;
}

nav {
    color: black;
}

h1 {
    @include title();
}

h2 {
    font-size: 1.5rem;
}

h3 {
    @include title();
}

a.logo {
    display: block;
    font-family: 'Ubuntu', sans-serif;
    font-size: 3rem;
    color: #fff !important; // letter-spacing: -7px; 
    font-weight: 400; // text-transform: uppercase;
    // animation: shine 5.75s ease-out infinite;
    // text-shadow: 0px 0px 5px #fff, 0px 0px 7px #fff;

}

.navbar--custom {
    // padding-left: 5%;
    // padding-right: 5%;
    // margin: 0 auto;
    align-content: center
}

.contacts {
    padding: 5%;
}

.colored {
    color: $theme_yellow;
}

.colored-link {
    @include colored-link();
}

.nav-item--phone {
    // margin-right: auto;
    align-self: center;
    flex-shrink: 0;

    &>a {
        color: #fff;
    }
}

.navbar-nav {
    flex-grow: 1;
    flex-wrap: wrap;
    justify-content: space-around;
    align-content: space-between;
}

.nav-item--gett {
    // width: 100%;
    // margin-left: auto;
    // margin: 0 auto;
}

.loader {
    // background-image: url('/img/loader.gif');
    background-color: rgba(0, 0, 0, 0.8);
    background-position: center center;
    background-repeat: no-repeat;
    text-transform: uppercase;
    font-size: 2.2rem;
    cursor: progress;
    position: fixed;
    color: $theme_yellow;
    z-index: 1097;
    bottom: 0px;
    right: 0px;
    /*left:250px;*/
    /*top:114px;*/
    left: 0;
    top: 0;


    &__animate {
        margin: 0 auto;
        text-align: center;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }


}



// @media only screen and (max-width: 767px) {
//     #loader {
//         left: 0px;
//         top: 0;
//     }
// }




@media screen and (max-width: 990px) {

    //сбрасваем у лого правый отступ
    .navbar-brand {
        margin-right: 0;
    }
}


@media screen and (max-width: 1180px) and (min-width: 990px) {

    .btn {
        font-size: 0.7rem !important;
    }

    .navbar-brand.logo {
        font-size: 1.2rem;
        margin-right: 0;
    }

    .nav-link--phone {
        font-size: 14px;
    }
}