// $break_md: 780px;
// $break_xs: 560px;

// $columns: 12;

// $offset: 30px;
// $offset_one_side: ($offset / 2);

// $color_blue_light: #73C0FF;
// $color_blue_dark: #7373FF;

$theme_yellow: #FAB622;

@keyframes shine {
    from {
      text-shadow:0px 0px 10px #fff,
        0px 0px 10px #fff, 
        0px 0px 25px #fff,
        0px 0px 25px #fff,
        0px 0px 25px #fff,
        0px 0px 25px #fff,
        0px 0px 25px #fff,
        0px 0px 25px #fff,
        0px 0px 50px #fff,
        0px 0px 50px #fff,
        0px 0px 50px #7B96B8,
        0px 0px 150px #7B96B8,
        0px 10px 100px #7B96B8,
        0px 10px 100px #7B96B8,
        0px 10px 100px #7B96B8,
        0px 10px 100px #7B96B8,
        0px -10px 100px #7B96B8,
        0px -10px 100px #7B96B8;
    }
  }