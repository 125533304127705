@font-face {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Ubuntu/Ubuntu-Regular.ttf") format(ttf); }

@keyframes shine {
  from {
    text-shadow: 0px 0px 10px #fff, 0px 0px 10px #fff,  0px 0px 25px #fff, 0px 0px 25px #fff, 0px 0px 25px #fff, 0px 0px 25px #fff, 0px 0px 25px #fff, 0px 0px 25px #fff, 0px 0px 50px #fff, 0px 0px 50px #fff, 0px 0px 50px #7B96B8, 0px 0px 150px #7B96B8, 0px 10px 100px #7B96B8, 0px 10px 100px #7B96B8, 0px 10px 100px #7B96B8, 0px 10px 100px #7B96B8, 0px -10px 100px #7B96B8, 0px -10px 100px #7B96B8; } }

body {
  background: linear-gradient(27deg, #151515 5px, transparent 5px) 0 5px, linear-gradient(207deg, #151515 5px, transparent 5px) 10px 0px, linear-gradient(27deg, #222 5px, transparent 5px) 0px 10px, linear-gradient(207deg, #222 5px, transparent 5px) 10px 5px, linear-gradient(90deg, #1b1b1b 10px, transparent 10px), linear-gradient(#1d1d1d 25%, #1a1a1a 25%, #1a1a1a 50%, transparent 50%, transparent 75%, #242424 75%, #242424);
  background-color: #131313;
  background-size: 20px 20px;
  color: white;
  min-height: 100vh; }

nav {
  color: black; }

h1 {
  font-size: 2rem; }
  @media screen and (max-width: 500px) {
    h1 {
      font-size: 1.5rem; } }

h2 {
  font-size: 1.5rem; }

h3 {
  font-size: 2rem; }
  @media screen and (max-width: 500px) {
    h3 {
      font-size: 1.5rem; } }

a.logo {
  display: block;
  font-family: 'Ubuntu', sans-serif;
  font-size: 3rem;
  color: #fff !important;
  font-weight: 400; }

.navbar--custom {
  align-content: center; }

.contacts {
  padding: 5%; }

.colored {
  color: #FAB622; }

.colored-link {
  color: #FAB622 !important; }
  .colored-link:hover {
    color: #FAB622 !important; }

.nav-item--phone {
  align-self: center;
  flex-shrink: 0; }
  .nav-item--phone > a {
    color: #fff; }

.navbar-nav {
  flex-grow: 1;
  flex-wrap: wrap;
  justify-content: space-around;
  align-content: space-between; }

.loader {
  background-color: rgba(0, 0, 0, 0.8);
  background-position: center center;
  background-repeat: no-repeat;
  text-transform: uppercase;
  font-size: 2.2rem;
  cursor: progress;
  position: fixed;
  color: #FAB622;
  z-index: 1097;
  bottom: 0px;
  right: 0px;
  /*left:250px;*/
  /*top:114px;*/
  left: 0;
  top: 0; }
  .loader__animate {
    margin: 0 auto;
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); }

@media screen and (max-width: 990px) {
  .navbar-brand {
    margin-right: 0; } }

@media screen and (max-width: 1180px) and (min-width: 990px) {
  .btn {
    font-size: 0.7rem !important; }
  .navbar-brand.logo {
    font-size: 1.2rem;
    margin-right: 0; }
  .nav-link--phone {
    font-size: 14px; } }

.intro__img {
  margin: 1rem; }

@media screen and (max-width: 500px) {
  .intro__img {
    width: 50px;
    margin: 5px; } }

.promo {
  padding: 10% 5%;
  font-size: 2rem; }
  @media screen and (max-width: 500px) {
    .promo {
      font-size: 1.5rem; } }
  .promo__phone {
    display: block;
    color: inherit; }
    .promo__phone:hover {
      color: #FAB622;
      text-decoration: none; }

.momentum {
  display: flex;
  justify-content: center;
  padding-top: 10%; }
  .momentum__item {
    box-sizing: border-box;
    flex-basis: 600px; }
  .momentum__text {
    display: block;
    min-height: 150px;
    padding: 20px 0 20px 150px;
    background-image: url("../img/moment3.svg");
    background-repeat: no-repeat;
    background-position-y: 25px;
    background-size: 80px auto; }
  .momentum__connect {
    text-align: center;
    color: #FAB622; }
    .momentum__connect:hover {
      color: #FAB622; }

@media screen and (max-width: 500px) {
  .momentum__text {
    padding-left: 90px; } }

.features {
  box-sizing: border-box;
  max-width: 1024px;
  padding-top: 12%;
  padding-bottom: 10%;
  padding-left: 15%;
  margin-left: auto;
  margin-right: auto; }
  .features__item {
    padding-top: 40px;
    padding-right: 20px;
    padding-bottom: 40px;
    padding-left: 0px;
    min-height: 150px; }
    .features__item--1 {
      background: url("../img/moment.svg") no-repeat;
      background-size: 90px auto;
      flex-grow: 1; }
    .features__item--2 {
      background: url("../img/salary.svg") no-repeat;
      background-size: 50px auto;
      max-width: 300px; }
    .features__item--3 {
      background: url("../img/comission.svg") no-repeat;
      background-size: 50px auto; }
    .features__item--4 {
      background: url("../img/getout.svg") no-repeat;
      background-size: 50px auto;
      max-width: 300px; }
  .features__title {
    margin-top: 20px; }

@media screen and (max-width: 500px) {
  .features {
    padding-left: 80px; } }

input.error {
  border: 1px solid #ff0000; }

input.valid {
  border: 1px solid green; }

label.error {
  color: #ff0000;
  font-weight: normal;
  font-size: 0.8rem; }

.connect-wrap {
  max-width: 800px;
  padding: 0 20px;
  margin: 0 auto; }

.instruction section {
  margin: 40px auto 60px auto; }

.instruction img {
  max-width: 100%;
  margin: 20px auto; }

.instruction__title {
  padding: 0 0 20px 32px; }

.content {
  padding: 30px 0; }
  .content__title {
    margin-bottom: 25px; }
  .content__link {
    color: #fff; }
    .content__link:hover {
      color: #FAB622; }

.important__title {
  color: #FAB622; }

.left-bordered {
  border-left: 2px solid #FAB622;
  padding: 10px 0 10px 30px; }

.install__link {
  color: #FAB622 !important; }
  .install__link:hover {
    color: #FAB622 !important; }

.connect__link {
  color: #FAB622 !important; }
  .connect__link:hover {
    color: #FAB622 !important; }

.dogovor-wrap {
  margin: 2% 10%; }
  .dogovor-wrap > main {
    border: 1px solid #eee;
    padding: 3%;
    background: white;
    color: #555; }

.rate {
  padding-bottom: 30px; }
  .rate__header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 5%; }

.links {
  padding: 5%;
  border: 1px solid #FAB622; }
  .links__link {
    color: #FAB622; }
    .links__link:hover {
      color: #FAB622;
      text-decoration: none; }
  .links li {
    margin-bottom: 15px; }

.registration__check-sms {
  margin: 0 auto;
  text-align: center;
  line-height: 46px;
  font-size: 26px;
  height: 46px;
  width: 160px; }

.registration__error {
  line-height: 28px;
  min-height: 28px; }

.coworkers__wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap; }

.coworkers__person {
  min-width: 350px;
  display: flex;
  justify-content: flex-start;
  align-items: center; }
  .coworkers__person:first-child {
    min-width: 100%;
    justify-content: center; }

@media screen and (max-width: 794px) {
  .coworkers__wrapper {
    justify-content: center; }
  .coworkers__person:first-child {
    min-width: 350px;
    justify-content: flex-start; } }

@media screen and (max-width: 500px) {
  .coworkers__wrapper {
    justify-content: center; }
  .coworkers__person {
    min-width: 320px;
    padding-bottom: 7px; }
    .coworkers__person:first-child {
      min-width: 320px; }
  .coworkers__img {
    width: 50px; } }
