.coworkers {
    &__wrapper {
        display: flex; // justify-content: center;
        align-items: center;
        flex-wrap: wrap
    }
    &__person {
        &:first-child {
            min-width: 100%;
            justify-content: center;
        }
        min-width: 350px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    &__img {}
    &__name {}
}

@media screen and (max-width: 794px) {
    .coworkers {
        &__wrapper {
            justify-content: center;
        }
        &__person {
            &:first-child {
                min-width: 350px;
                justify-content: flex-start;
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .coworkers {
        &__wrapper {
            justify-content: center;
        }
        &__person {
            &:first-child {
                min-width: 320px;
            }
            min-width: 320px;
            padding-bottom: 7px;
        }
        &__img {
            width: 50px;
        }
        &__name {}
    }
}