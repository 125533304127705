.links {
    padding: 5%;
    border: 1px solid $theme_yellow;

    &__link {
        color: $theme_yellow;
        &:hover {
            color: $theme_yellow;
            text-decoration: none;
        }
    }

    li{
        margin-bottom: 15px;
    }
}