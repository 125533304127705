.intro {
    &__img {
        margin: 1rem;
    }
}

@media screen and (max-width: 500px) {
    
    .intro {
        &__img {
            width: 50px;
            margin: 5px;
        }
    }

}