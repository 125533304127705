.promo {
    padding: 10% 5%;
    @include title();
    &__phone {
        display: block;
        color: inherit;
        &:hover {
            color: $theme_yellow;
            text-decoration: none;
        }
    }
}