.registration {

    &__check-sms {
        margin: 0 auto;
        text-align: center;
        line-height:46px;
        font-size:26px;
        height:46px;
        width:160px;
    }

    &__error {
        line-height: 28px;
        min-height: 28px;
    }

}