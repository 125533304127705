.momentum {
    display: flex;
    justify-content: center;
    padding-top: 10% ;

    &__item {
        box-sizing: border-box;
        flex-basis: 600px;
    }

    &__text {
        display: block;
        min-height: 150px;
        padding: 20px 0 20px 150px;
        background-image: url('../img/moment3.svg');
        background-repeat: no-repeat;
        background-position-y: 25px;
        background-size: 80px auto;

    }

    &__connect {

        text-align: center;
        color: $theme_yellow;
        &:hover {
            color: $theme_yellow;
        }
    }
}


@media screen and (max-width: 500px) {

    .momentum {
        // &__item {
        //     box-sizing: border-box;
        //     flex-basis: 600px;
        // }
        &__text {
            padding-left: 90px;
        }
    }
}